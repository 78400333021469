import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as L from 'leaflet';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'stroly-dashboard';
  locationPathname = '';
  isMobile =
    L.Browser.mobile &&
    !sessionStorage.getItem('enablePCMode') &&
    window.parent.screen.width < 1024 &&
    !(
      window.parent.screen.height >= 1024 &&
      window.orientation.toString().indexOf('90') != -1
    );
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  // 翻訳済みテキスト
  helpGuide = '';
  terms = '';
  privacyPolicy = '';
  operatingCompany = '';

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    translate: TranslateService,
  ) {
    // zone.onMicrotaskEmpty.subscribe(() => {
    //   this.locationPathname = location.pathname;
    //   console.log(location.pathname, "detect change");
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.locationPathname = event.url;
        this.cd.markForCheck();
      }
    });

    translate.setDefaultLang('en');
    translate.addLangs(['en', 'ja']);
    const lang = translate.getBrowserLang()?.includes('ja') ? 'ja' : 'en';
    translate.use(lang);

    translate
      .get([
        'toolbar.HelpGuide',
        'toolbar.Terms',
        'toolbar.PrivacyPolicy',
        'toolbar.OperatingCompany',
      ])
      .subscribe((translations) => {
        this.helpGuide = translations['toolbar.HelpGuide'];
        this.terms = translations['toolbar.Terms'];
        this.privacyPolicy = translations['toolbar.PrivacyPolicy'];
        this.operatingCompany = translations['toolbar.OperatingCompany'];
      });
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      document
        .getElementsByTagName('app-root')[0]
        .setAttribute('style', 'min-width: 1400px');
    } else {
      // ダブルタップによる拡大操作無効化
      let lastTouch = 0;
      document.addEventListener(
        'touchend',
        (event) => {
          const now = window.performance.now();
          if (now - lastTouch <= 500) {
            event.preventDefault();
          }
          lastTouch = now;
        },
        true,
      );
    }
  }
}
