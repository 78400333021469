import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-irregular-data',
  templateUrl: './irregular-data.component.html',
  styleUrls: ['./irregular-data.component.css'],
})
export class IrregularDataComponent implements OnInit {
  message = '';

  constructor(private translate: TranslateService) {
    this.translate.get(['irregularData.Message1']).subscribe((translations) => {
      this.message = translations['irregularData.Message1'];
    });
  }

  ngOnInit(): void {}
}
