import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-not-found-page',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPage implements OnInit {
  title = '';
  message: string[] = [];
  forTop = '';

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate
      .get([
        'pageNotFound.Title',
        'pageNotFound.Caption1',
        'pageNotFound.Caption2',
        'pageNotFound.ForTop',
      ])
      .subscribe((translations) => {
        this.title = translations['pageNotFound.Title'];
        this.message = [
          translations['pageNotFound.Caption1'],
          translations['pageNotFound.Caption2'],
        ];
        this.forTop = translations['pageNotFound.ForTop'];
        cd.detectChanges();
      });
  }

  ngOnInit(): void {}
}
